import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import authReducer from "./authReducer";
import adminsReducer from "./adminsReducer";
import anuncioReducer from "./anuncioReducer";
import searchReducer from "./searchReducer";
import mapaReducer from "./mapaReducer";
import clusterReducer from "./clusterReducer";

export default combineReducers({
    user: usersReducer,
    admins: adminsReducer,
    authentication: authReducer,
    search: searchReducer,
    cluster: clusterReducer,
    selectCategoria: searchReducer,
    anuncio: anuncioReducer,
    mapa: mapaReducer
});
