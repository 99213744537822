// Startup point for the client side application
import "@babel/polyfill";
import Global from "./../helpers/variables";

var global = Global;

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { renderRoutes } from "react-router-config";
import Keycloak from "keycloak-js";

import axios from "axios";
import Routes from "./Routes";
import reducers from "./reducers";
import { createBrowserHistory } from "history";
import { Container } from "./components/commons/theme-styled";
import { FETCH_CURRENT_USER } from "./actions/types";
import { Helmet } from "react-helmet";

import FavIon32 from "./resources/imgs/favicons/homescreen-V2_favicon-32x32.png";
import FavIconIos76 from "./resources/imgs/favicons/homescreen-V2_ios-76x76.png";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();
const history = createBrowserHistory();

const axiosInstance = axios.create({
    baseURL: "/api"
});

const store = createStore(
    reducers,
    window.INITIAL_STATE,
    applyMiddleware(thunk.withExtraArgument(axiosInstance))
);

window.keycloak = Keycloak({
    realm: "accounts",
    url: `${process.env.ACCOUNTS_URL}`,
    "ssl-required": "external",
    clientId: "classi-motos-ssr",
    "public-client": true,
    "confidential-port": 0
});

window.keycloak
    .init({ onLoad: "check-sso" })
    .success(authenticated => {

        if (authenticated) {
            let keycloakData = {};
            keycloakData.isAuthenticated = authenticated;
            keycloakData.userEmail = window.keycloak.idTokenParsed.email;
            keycloakData.userName = window.keycloak.idTokenParsed.given_name;

            store.dispatch({
                type: FETCH_CURRENT_USER,
                payload: keycloakData
            });

            setInterval(() => {
                window.keycloak.updateToken(10).error(() => {
                    console.log("LOGOUT");
                    window.keycloak.logout();
                });
            }, 10000);
        }

    })
    .error(e => {
        console.log("failed to initialize");
    });

// Evento para fechar a intervençao
var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
var eventer = window[eventMethod];
var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

eventer(messageEvent, function (e) {

    if (e.data === "close-intervencao" || e.message === "close-intervencao") {
        var intervencaoHolders = document.getElementsByClassName('intervencao-holder');
        for (var i = 0; i < intervencaoHolders.length; i++) {
            intervencaoHolders[i].style.display = 'none';
        }
    }

});

ReactDOM.hydrate(
    <React.Fragment>
        <Helmet>
            <link rel="icon" type="image/png" href={FavIon32} />
            <link rel="apple-touch-icon" sizes="76x76" href={FavIconIos76} />
        </Helmet>
        <Provider store={store}>
            <Router history={history}>
                <div>
                    <Container direction="column" noPadding={true} />
                    {renderRoutes(Routes)}
                </div>
            </Router>
        </Provider>
    </React.Fragment>,
    document.querySelector("#root")
);
