import React from 'react';
import { connect } from 'react-redux';
import { Hidden } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import Modal from '../modal/modal';
import { ModalList, ModalItemList } from '../modal/modal-styled';
import Menssagem from './mensagem';

import {
    ContatoContainer,
    ContatoDesktopContainer,
    ContatoButton,
    FabContainer,
    FabOverlay,
    FabBtn,
    FloatBtn,
    SvgIcon,
    AnuncianteBox
} from './contato-styled';

// Icons
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import PhoneIcon from '@material-ui/icons/Phone';
import Message from '@material-ui/icons/MailOutline';
import WhatsappIcon from './../../resources/svg/whatsapp.svg';

// Analytics
import { analyticsEvent } from '../analytics-gjc/analytics-gjc-mockup';
import { sendAnalytics } from '../analytics-gjc/analytics-gjc';

class Contato extends React.Component {

    constructor(props) {

        super(props);
        this.contactBox = React.createRef();
        this.modalRef = React.createRef();

        this.state = {
            vitrine: 'carros',
            floatBtn: false,
            contactBoxClass: ''
        }

    }

    componentDidMount() {
        this.setState({ windowWidth: window.innerWidth });
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {

        let windowOffset = window.pageYOffset - 130;

        if (windowOffset > 0) {
            this.setState({ contactBoxClass: 'stick' });
        } else {
            this.setState({ contactBoxClass: '' });
        }

    };

    handlePhoneClick = () => {

        sendAnalytics(analyticsEvent.VER_TELEFONE, this.props.analyticsJson);

        this.setState({
            modalTitle: this.props.anunciante.nome,
            modalSubTitle: isMobile ? 'Clique no número para ligar' : null,
            modalContent: 'phone',
            floatBtn: false
        });

        this.modalRef.current.toggleModal();

    };

    handleMesageClick = () => {

        sendAnalytics(analyticsEvent.ENTRAR_CONTATO, this.props.analyticsJson);

        this.setState({
            modalContent: 'message',
            modalTitle: null,
            modalSubTitle: null,
            floatBtn: false
        });

        this.modalRef.current.toggleModal();

    };

    renderTelefone = (type) => {

        const { anunciante } = this.props;
        const { floatBtn } = this.state;

        if (anunciante && anunciante.telefones && anunciante.telefones.length) {

            switch (type) {

                case 'desktop':

                    return (
                        <>
                            <ContatoButton id="ver_telefone" className="phone" onClick={this.handlePhoneClick}>Ligar para anunciante</ContatoButton>
                        </>
                    );

                case 'floating':

                    return (
                        <>
                            <FloatBtn id="ver_telefone" className="phone" in={floatBtn.toString()} aria-label="Telefones" onClick={this.handlePhoneClick} disabled={!this.state.floatBtn}>
                                <PhoneIcon id="ver_telefone_0" className="icon" />
                                VER TELEFONE
                            </FloatBtn>
                        </>
                    );

            }

        }

    }

    renderWhatsapp = (type) => {

        const { defaultMsg, anunciante } = this.props;

        if (anunciante && anunciante.telefones && anunciante.telefones.length) {

            let telefoneWhatsApp = anunciante.telefones.filter(e => e.isWhatsapp)[0];

            if (telefoneWhatsApp) {

                switch (type) {

                    case 'desktop':

                        return (
                            <>
                                <ContatoButton id="whatsapp"
                                    className="whatsapp"
                                    component="a"
                                    href={`https://wa.me/55${telefoneWhatsApp.numero}?text=Olá, vi seu anúncio no CLASSI! Quero saber mais sobre o anúncio ${defaultMsg}.`}
                                    target="_blank"
                                    variant="contained"
                                    onClick={() => sendAnalytics(analyticsEvent.VER_WHATSAPP, this.props.analyticsJson)}>Mensagem via Whatsapp</ContatoButton>
                            </>
                        );

                    case 'floating':

                        return (
                            <>
                                <FloatBtn id="whatsapp"
                                    className="whatsapp"
                                    component="a"
                                    in={this.state.floatBtn.toString()}
                                    target="_blank"
                                    href={`https://wa.me/55${telefoneWhatsApp.numero}?text=Olá, vi seu anúncio no CLASSI! Quero saber mais sobre o anúncio ${defaultMsg}.`}
                                    onClick={() => sendAnalytics(analyticsEvent.VER_WHATSAPP, this.props.analyticsJson)} 
                                    disabled={!this.state.floatBtn}>
                                    <SvgIcon id="whatsapp_0" className="icon" src={WhatsappIcon} />
                                    WHATSAPP
                                </FloatBtn>
                            </>
                        );

                }

            }

        }

    };

    renderModal = () => {

        const { anunciante } = this.props;

        if (this.state.modalContent === 'phone') {

            if (isMobile) {

                return (

                    <ModalList>
                        {anunciante.telefones && anunciante.telefones.map((item, index) =>
                            <ModalItemList key={item.numero}>
                                <a id={`click_call_${index}`} href={`tel:+55${item.numero}`} onClick={() => sendAnalytics(analyticsEvent.CLICK_TO_CALL, this.props.analyticsJson)}>
                                    {item.numeroFormatado}
                                </a>
                            </ModalItemList>
                        )}
                    </ModalList>

                );

            } else {

                return (

                    <ModalList>
                        {anunciante.telefones && anunciante.telefones.map(item =>
                            <ModalItemList key={item.numero}>
                                {item.numeroFormatado}
                            </ModalItemList>
                        )}
                    </ModalList>

                );

            }

        } else if (this.state.modalContent === 'message') {

            if (anunciante) {

                return (

                    <div style={{ padding: 20 }}>
                        <AnuncianteBox>
                            <div className="logo">
                                {anunciante.urlLogo && <img src={anunciante.urlLogo} />}
                            </div>
                            <div className="nome">
                                <span className="label">Anunciante</span>
                                <span>{anunciante.nome}</span>
                            </div>
                        </AnuncianteBox>
                        <Menssagem {...this.props}></Menssagem>
                    </div>

                );

            } else {

                return (
                    <div style={{ padding: 20 }}>
                        <Menssagem {...this.props}></Menssagem>
                    </div>
                );

            }

        }

    };

    toogleFabBtn = () => {
        this.setState({ floatBtn: !this.state.floatBtn });
    };

    render() {

        const { floatBtn, contactBoxClass, modalTitle, modalSubTitle } = this.state;
        const { anunciante, hideForm } = this.props;

        if (hideForm && !(anunciante && anunciante.telefones && anunciante.telefones.length)) {
            return null;
        } else {

            return (

                <>

                    <Hidden smDown>

                        <ContatoContainer>

                            <ContatoDesktopContainer ref={this.contactBox} className={contactBoxClass} >

                                {anunciante &&
                                    <AnuncianteBox>

                                        <div className="logo">
                                            {anunciante.urlLogo && <img src={anunciante.urlLogo} />}
                                        </div>

                                        <div className="nome">
                                            <span className="label">Anunciante</span>
                                            <span>{anunciante.nome}</span>
                                        </div>

                                    </AnuncianteBox>
                                }

                                {this.renderWhatsapp('desktop')}
                                {this.renderTelefone('desktop')}

                                {!hideForm && <ContatoButton id="entrar_contato" className="info" onClick={this.handleMesageClick}>Quero mais informações</ContatoButton>}

                            </ContatoDesktopContainer>

                        </ContatoContainer>

                    </Hidden>

                    <Hidden mdUp>

                        <FabContainer>

                            {floatBtn &&
                                <FabOverlay onClick={() => this.setState({ floatBtn: false })} />
                            }

                            {!hideForm &&
                                <FloatBtn id="entrar_contato" className="message" in={floatBtn.toString()} aria-label="Mensagem" onClick={this.handleMesageClick} disabled={!this.state.floatBtn}>
                                    <Message id="entrar_contato_0" className="icon" />
                                    MAIS INFORMAÇÕES
                                </FloatBtn>
                            }

                            {this.renderTelefone('floating')}
                            {this.renderWhatsapp('floating')}

                            <FabBtn aria-label="Contatos" onClick={this.toogleFabBtn} >
                                <AddIcCallIcon />
                            </FabBtn>

                        </FabContainer>

                    </Hidden>

                    <Modal ref={this.modalRef} titulo={modalTitle} subtitulo={modalSubTitle}>
                        {this.renderModal()}
                    </Modal>

                </>

            );

        }

    }

}

const mapStateToProps = store => ({
    storedUserFiles: store.user.storedUserFiles,
    authentication: store.authentication.authentication
});

export default connect(mapStateToProps, null)(Contato)
