import React, { Component } from 'react';
import { CardSuggestPaper, PreviewImg, CardSuggestTitulo, CardSuggestValor, CardSuggestSubtitulo } from './SuggestCard-styled';
import Favoritar from '../favoritar/favoritar';
import { withRouter } from 'react-router';
import { buildUrl } from '../../components/image-render/image-render';

class SuggestCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { authentication } = this.props;
        const { imagens, titulo, valor, id, localizacao, favortio, url } = this.props.anuncio;
        const { cidade, estado } = localizacao;

        return (
            <CardSuggestPaper>
                <Favoritar
                    isFavorite={favortio}
                    idAnuncio={id}
                    authentication={authentication}
                />

                <PreviewImg
                    to={{ pathname: url ? url : null }}
                    src={imagens && imagens.length > 0 ? buildUrl({src: imagens[0].imageUrl, width: 200, height: 200, resizeType:'fill'}): null}                    
                    target="_self"
                />

                <CardSuggestTitulo to={{ pathname: url ? url : null }} replace={true}>
                    {titulo}
                </CardSuggestTitulo>

                <CardSuggestSubtitulo to={{ pathname: url ? url : null }}>
                    {cidade && estado && `${cidade} - ${estado}`}
                </CardSuggestSubtitulo>

                <CardSuggestValor>
                    {valor && `R$ ${valor}`}
                    {!valor && 'Valor a combinar'}
                </CardSuggestValor>
            </CardSuggestPaper>
        )

    }

}

export default withRouter(SuggestCard);
