import styled, { css, keyframes } from 'styled-components';
import Select from 'react-select';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import { IconButton, Fab } from '@material-ui/core';
import InputMask from 'react-input-mask';

// Icones
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import HighlightOff from '@material-ui/icons/HighlightOff';

export const ContatoContainer = styled.div`

    width: 350px;
    height: auto;
    max-height: 600px;
    overflow: hidden;
    border-radius: 5px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: ${props => props.padding ? props.padding : null};

`;

export const ContatoDesktopContainer = styled.div`

    background-color: #EBEBEB;
    padding: 20px;
    width: 350px;

    &.stick {
        position: fixed;
        top: 70px;
    }

`;

export const ContatoButton = styled(Button)`
    && {
        width: 100%;
        padding: 10px 20px;

        &.whatsapp{
            background-color: #4CAF50;
            color: #FFFFFF; 
            margin-bottom: 15px;
        }

        &.phone{ 
            background-color: #3D1954;
            color: #FFFFFF; 
            margin-bottom: 15px;
        }

        &.info{
            background-color: #FFF;
            color: #000000; 
        }

        -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);

        @media screen and (max-width: 768px){
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
`;

export const FabContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: fixed;
    bottom: 20px;
    right: 10px;
`;

export const FabOverlay = styled.div`
    background-color: rgba(0, 0, 0, .7);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const FloatBtn = styled(IconButton)`
    &&{
        width: 200px;
        height: 50px;
        animation: ${props => props.in === "true" ? css`${fadeIn} .2s linear` : null};
        animation: ${props => props.in === "false" ? css`${fadeOut} .2s linear` : null};
        animation-fill-mode: both;
        position: relative;
        opacity: 0;
        bottom: -20px;
        transform: scale(.5, .5);

        background-color: #FFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        margin-top: 5px;
        margin-bottom: 5px;

        font-size: 14px;
        border-radius: 25px;
        
        .icon {
            margin-right: 5px;
        }

        &:nth-child(0){
            animation-delay: .05s;
        }

        &:nth-child(1){
            animation-delay: .1s;
        }

        &.phone .icon{ color: #3140a8; }

        &.message .icon{ color: #db4343; }

        &:hover{
            background-color: ${darken('#FFFFFF', 0.2)};
        }
    }
`;

export const FabBtn = styled(Fab)`
    &&{
        width: 70px;
        height: 70px;
        background-color: #2b043f;
        color: #FFF;

        margin-top: 10px;

        &:hover{
            background-color: ${darken('#2b043f', 0.2)};
        }
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: scale(.5, .5);
        bottom: -20px;
    }

    to {
        opacity: 1;
        transform: scale(1, 1);
        bottom: 0px;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
        bottom: 0px;
        transform: scale(1, 1);
    }

    to {
        opacity: 0;
        bottom: -20px;
        transform: scale(.5, .5);
    }
`;

export const SvgIcon = styled.img`
    width: 25px;
`;

export const AnuncianteBox = styled.div`
    display: flex;
    margin-bottom: 20px;

    .logo {
       width: 60px;
    
       img {
           width: 100%;
       }
       
    }

    .nome {
        width: calc(100% - 60px);
        
        span {
            display: block;
            text-align: right;
            font-size: 14px;

            &.label {
                color: #525252;
                font-size: 12px;
            }

        }

    }

`;

export const MessageContainer = styled.div`

    width: 380px;
    height: auto;
    max-height: 600px;
    overflow: hidden;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px){
        width: 100%;
        display: block;
    }

`;

export const Aviso = styled.div`
    padding: 10px;
    color: #634b24;
    background-color: #fdecc9;
    margin-bottom: 5px;

    display: ${props => props.showError ? 'flex' : 'none'};
    flex-direction: row;

    align-items: center;

    p {
        margin-left: 5px;
    }
`;

export const AlertIcon = styled(ErrorOutline)`
    && {
        font-size: 32px;
        color: #634b24;
    }
`;

export const ContatoInput = styled(InputMask)`
    width: 100%;
    height: 40px;

    font-size: 16px;
    font-weight: bold;
    color: gray;

    padding: 10px;
    border: 1px solid #979797;
    margin-bottom: 10px;

    outline: none;

    &:disabled {
        opacity: .5;
    }

`;

export const ContatoSelect = styled(Select).attrs({
    classNamePrefix: 'select'
})` 
    &&{
        width: 100%;
        margin-bottom: 10px;
    }

    .select_{

        &_control{
            background-color: transparent;
            border: 1px solid #979797;
            border-radius: 0;
            box-shadow: none;

            &:hover{
                background-color: transparent;
                border: 1px solid #979797;
                border-radius: 0;
                box-shadow: none;
            }
        }

        &_single-value{
            width: 100%;
        }

        &_placeholder, &_indicator svg, &_menu, &_single-value, &_input{
            color: gray;
            font-size: 16px;
            font-weight: bold;
        }

        &_indicator-separator{
            background-color: #979797;;
        }

        &_option{
            color: gray;
            background-color: #FFF;
        }

        &_option:hover, &_option--is-focused{
            background-color: ${lighten('#e5e5e5', 0.5)};
            cursor: pointer;
        }

        &_menu{
            border-radius: 0;
            margin-top: 0;
        }
    }
`;

export const ContatoTextArea = styled.textarea`
    width: 100%;
    height: 90px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #979797;
    color: ${props => props.vitrinecolor ? 'gray' : '#FFF'};
    resize: none;

    outline: none;
`;

export const SendBtn = styled(Button)`
    &&{
        width: 100%;
        font-size: 16px;
        color: #FFF;
        background-color: #ffa002;
        margin-top: 10px;
        margin-bottom: 5px;

        &:hover{
            background: ${darken('#ffa002', 0.1)};
        }

        &.mobile-contato {
            height: 64px;
        }
    }
`;

export const MsgFeedback = styled.div`
    display: ${props => props.showMsg ? 'flex' : 'none'};
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #FFF;
    padding: 20px;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const CheckIcon = styled(CheckCircle)`
    &&{
        font-size: 120px;
        color: green;
    }
`;

export const ErrorIcon = styled(HighlightOff)`
    &&{
        font-size: 120px;
        color: red;
    }
`;
