import styled from "styled-components";

// Components
import Button from "@material-ui/core/Button";
import { darken } from "@material-ui/core/styles/colorManipulator";

// Icons
import CheckCircle from "@material-ui/icons/CheckCircle";
import HighlightOff from "@material-ui/icons/HighlightOff";

export const Reportar = styled.div`
  width: 100%;
  position: relative;
`;

export const ReportButton = styled(Button)`
  && {
    font-size: 18px;
    color: #940057;
    margin-bottom: 10px;
  }
`;

export const ReportForm = styled.form`
  max-height: ${props => (props.showReport ? "700px" : "0px")};
  transition: max-height 0.5s;
  overflow: hidden;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
`;

export const ReportFormTitle = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #940057;

  font-size: 21px;
  font-weight: 400;
  color: #fff;
`;

export const ReportFormContainer = styled.div`
  padding: 10px;
`;

export const ReportFormBtn = styled(Button)`
  && {
    width: 100%;
    font-size: 16px;
    color: #fff;
    background-color: #940057;
    border-radius: 0;

    &:hover {
      background-color: ${darken("#940057", 0.1)};
    }
  }
`;

export const ReportOption = styled.label`
  margin-bottom: 20px;
  user-select: none;
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;

  input.checkmark {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border: 2px solid #616262;
    margin-right: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    outline: 0;
    position: relative;

    &:checked {
      border-color: #940057;

      &:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 10px;
        display: table;
        width: 6px;
        height: 15px;
        border: 3px solid #940057;
        border-top-width: 0;
        border-left-width: 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  span {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ReportLabel = styled.p`
    display: ${props => (props.showOthers ? "block" : "none")}
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const ReportText = styled.textarea`
    width: 100%;
    height: 200px;
    resize: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #940057;

    display: ${props => (props.showOthers ? "block" : "none")}

    overflow: hidden;

    border: 2px solid #940057;
    margin-bottom: 10px;
    outline: none;
`;

export const ReportFeedback = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;

  display: ${props => (props.showMsg ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 80px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const SuccessIcon = styled(CheckCircle)`
  && {
    font-size: 120px;
    color: green;
  }
`;

export const ErrorIcon = styled(HighlightOff)`
  && {
    font-size: 120px;
    color: red;
  }
`;
