import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: calc(100% - 100px);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 720px){
        flex-direction: column;
    }
`;

export const Card = styled.div`
    width: 90px;
    height: 110px;

    background-color: #FFF;
    background-image: url('${props => props.icon}');
    background-position: 52% 57%;
    background-repeat: no-repeat;
    background-size: 35% 40%;
    border: 3px solid ${ props => props.color ? props.color : '#000' };;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-left: 5px; 
    margin-right: 5px;

    &:hover{
        cursor: pointer;

        .slide-text{
            height: 60%;
        }
    }

    @media screen and (max-width: 720px){
        width: 100%;
        height: 60px;
        flex-direction: row;
        align-items: center;

        margin-top: 5px;
        margin-bottom:: 5px;

        &:hover{    
            .slide-text{
                height: 100%;
            }
        }
    }
`;

export const Titulo = styled.p`
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;

    color: ${ props => props.color ? props.color : '#000' };

    margin-top: 5px;
    margin-bottom: 5px;

    @media screen and (max-width: 720px){
        width: 90px;
    }
`;

export const SlideText = styled.div`
    width: 100%;
    height: 30%;
    padding: 10px;
    text-align: center;

    font-weight: bold;
    color: #FFF;
    background-color: ${props => props.color ? props.color : '#000'}

    transition: height 500ms ease;

    @media screen and (max-width: 720px){
        width: calc(100% - 90px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;