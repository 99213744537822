import React from "react";
import styled from "styled-components";
import Select from "react-select";
import Async from "react-select/lib/Async";
import Close from "@material-ui/icons/Close";
import { lighten } from "@material-ui/core/styles/colorManipulator";

export const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

// TODO: Alterar cores do selectinput
export const SelectInput = styled(Select).attrs({
    classNamePrefix: "select"
})`
    && {
        width: "100%";
        min-width: "150px";

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .select_{
        &_control,
        &_control:hover {
            border: 2px solid ${props => props.vitrinecolor};
            border-radius: 0;
            box-shadow: none;
        }

        &_control--is-disabled {
            opacity: 0.5;
            background-color: #FFF;
        }

        &_placeholder,
        &_indicator svg,
        &_menu,
        &_single-value,
        &_input {
            color: ${props => props.vitrinecolor};
            font-size: 16px;
            font-style: italic;
        }

        &_indicator-separator {
            background-color: ${props => props.vitrinecolor};
        }

        &_option {
            color: ${props => props.vitrinecolor};
        }

        &_option:hover,
        &_option--is-focused {
            background-color: ${lighten("#940057", 0.8)};
            cursor: pointer;
        }
    }
`;

export const TagContainer = styled.div`
    width: 100%;
    max-width: 230px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const TagItem = styled.div`
    background-color: #940057;
    color: #fff;
    padding: 5px;
    margin: 2px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`;

export const RemoveTag = styled(Close)`
    && {
        font-size: 18px;
        line-height: 1;
        cursor: pointer;
    }
`;
