import React from "react";
import PropTypes from "prop-types";

// Components
import Favoritar from "../favoritar/favoritar";
import { sendAnalytics } from "../analytics-gjc/analytics-gjc";
import { analyticsEvent } from "./../../components/analytics-gjc/analytics-gjc-mockup";

// Colors
import { Theme } from "./../../components/commons/vitrine-colors";

// Styles
import {
    CardContainer,
    CardPrioridade,
    CardTituloContainer,
    CardTitulo,
    CardInfo,
    CardSubtitulo,
    CardBtn,
    CardPreco,
    CardLink,
    CardImageContainer,
    CardImage,
    CardInfoContainer,
    CardIconContainer,
    CardItem,
    CardIcon,
    CardIconLabel,
    CardLogo,
    CardBottomInfo,
    CardButtonContainer
} from "./card-anuncio-styled";

// Images
import ImovelVendaSVG from "./../../resources/svg/vitrines/imovel-venda.svg";
import ImovelAluguelSVG from "./../../resources/svg/vitrines/imovel-aluguel.svg";
import CarrosSVG from "./../../resources/svg/vitrines/carros.svg";
import MotosSVG from "./../../resources/svg/vitrines/motos.svg";
import AgroSVG from "./../../resources/svg/vitrines/agro.svg";
import NegociosSVG from "./../../resources/svg/vitrines/negocios.svg";
import EmpregosSVG from "./../../resources/svg/vitrines/empregos.svg";

import ImovelVendaSVG_White from "./../../resources/svg/vitrines/white/imovel-venda-white.svg";
import ImovelAluguelSVG_White from "./../../resources/svg/vitrines/white/imovel-aluguel-white.svg";
import CarrosSVG_White from "./../../resources/svg/vitrines/white/carros-white.svg";
import MotosSVG_White from "./../../resources/svg/vitrines/white/motos-white.svg";
import AgroSVG_White from "./../../resources/svg/vitrines/white/agro-white.svg";
import NegociosSVG_White from "./../../resources/svg/vitrines/white/negocios-white.svg";
import EmpregosSVG_White from "./../../resources/svg/vitrines/white/empregos-white.svg";

import CalendarioSVG from "./../../resources/svg/calendario.svg";
import VelocimetroSVG from "./../../resources/svg/velocimetro.svg";
import CambioSVG from "./../../resources/svg/cambio.svg";
import CombustivelSVG from "./../../resources/svg/combustivel.svg";
import CorSVG from "./../../resources/svg/cor.svg";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class CardAnuncio extends React.Component {
    constructor(props) {
        super(props);

        this.sendCardClick = this.sendCardClick.bind(this);
    }

    getDefaultImg = () => {
        switch (this.props.vitrine) {
            case "IMOVEL_VENDA":
                return { img: ImovelVendaSVG, imgWhite: ImovelVendaSVG_White };
            case "IMOVEL_ALUGUEL":
                return {
                    img: ImovelAluguelSVG,
                    imgWhite: ImovelAluguelSVG_White
                };
            case "VEICULOS":
                return { img: CarrosSVG, imgWhite: CarrosSVG_White };
            case "MOTOS":
                return { img: MotosSVG, imgWhite: MotosSVG_White };
            case "AGRONEGOCIO":
                return { img: AgroSVG, imgWhite: AgroSVG_White };
            case "NEGOCIOS":
                return { img: NegociosSVG, imgWhite: NegociosSVG_White };
            case "EMPREGOS":
                return { img: EmpregosSVG, imgWhite: EmpregosSVG_White };
            default:
                return NegociosSVG;
        }
    };

    componentDidMount() {
        sendAnalytics(analyticsEvent.IMPRESSAO_CARD_ANUNCIO, { idAnuncio: this.props.idAnuncio });
    }

    sendCardClick() {
        sendAnalytics(analyticsEvent.CLICK_ANUNCIO, { idAnuncio: this.props.idAnuncio });
    }

    render() {
        const defaultImg = this.getDefaultImg();
        const {
            url,
            authentication,
            thumbnail,
            titulo,
            subtitulo,
            valorCombinar,
            isFavorite,
            idAnuncio,
            valor,
            prioridade,
            cardLogo,
            anoVeiculo,
            quilometragem,
            combustivel,
            cambio,
            cor
        } = this.props;

        return (
            <CardContainer
                className={`${prioridade.toLowerCase()}`}
                vitrinecolor={Theme[vitrineType].main}
                onClick={this.sendCardClick}
            >
                <CardImageContainer>
                    <CardImage
                        to={{ pathname: url ? url : "/404" }}
                        target="_blank"
                        src={thumbnail}
                    />

                    <Favoritar
                        isFavorite={isFavorite}
                        idAnuncio={idAnuncio}
                        authentication={authentication}
                    />
                </CardImageContainer>

                <CardInfoContainer>
                    <CardPrioridade
                        className={`${prioridade.toLowerCase()}`}
                        vitrinecolor={Theme[vitrineType].main}
                    >
                        {prioridade == "MEDIA" && <span>DESTAQUE</span>}
                        {prioridade == "ALTA" && <span>SUPER DESTAQUE</span>}
                    </CardPrioridade>

                    <CardInfo>
                        {!valorCombinar && (
                            <CardPreco>
                                R${" "}
                                {valor.toLocaleString("pt-br", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </CardPreco>
                        )}
                        {valorCombinar && (
                            <CardPreco>Valor a combinar</CardPreco>
                        )}

                        <CardTituloContainer>
                            <CardTitulo to={{ pathname: url ? url : "/404" }} target="_blank">
                                {titulo}
                            </CardTitulo>
                            <br />
                            <CardSubtitulo to={{ pathname: url ? url : "/404" }} target="_blank">
                                {subtitulo}
                            </CardSubtitulo>
                        </CardTituloContainer>

                        <CardBottomInfo>
                            {/* Icons */}
                            <CardIconContainer>
                                {anoVeiculo &&
                                    <CardItem>
                                        <CardIcon src={CalendarioSVG} />
                                        <CardIconLabel>
                                            {`${anoVeiculo.fabricacao}/${anoVeiculo.modelo}`}
                                        </CardIconLabel>
                                        <CardIconLabel>Ano</CardIconLabel>
                                    </CardItem>
                                }

                                {quilometragem &&
                                    <CardItem>
                                        <CardIcon src={VelocimetroSVG} />
                                        <CardIconLabel>
                                            {quilometragem}
                                        </CardIconLabel>
                                        <CardIconLabel>Km</CardIconLabel>
                                    </CardItem>
                                }

                                {combustivel &&
                                    <CardItem>
                                        <CardIcon src={CombustivelSVG} />
                                        <CardIconLabel>
                                            {combustivel}
                                        </CardIconLabel>
                                        <CardIconLabel>Combustível</CardIconLabel>
                                    </CardItem>
                                }

                                {cambio &&
                                    <CardItem>
                                        <CardIcon src={CambioSVG} />
                                        <CardIconLabel>
                                            {cambio}
                                        </CardIconLabel>
                                        <CardIconLabel>Câmbio</CardIconLabel>
                                    </CardItem>
                                }

                                {cor &&
                                    <CardItem>
                                        <CardIcon src={CorSVG} />
                                        <CardIconLabel>
                                            {cor}
                                        </CardIconLabel>
                                        <CardIconLabel>Cor</CardIconLabel>
                                    </CardItem>
                                }
                            </CardIconContainer>

                            {/* Button */}
                            <CardButtonContainer>
                                {cardLogo &&
                                    <CardLogo src={cardLogo} />
                                }
                            </CardButtonContainer>
                        </CardBottomInfo>

                        <CardBottomInfo>
                            <CardLink to={{ pathname: url ? url : "/404" }} target="_blank">
                                <CardBtn vitrinecolor={Theme[vitrineType].main}>
                                    Ver detalhes
                                </CardBtn>
                            </CardLink>
                        </CardBottomInfo>
                    </CardInfo>
                </CardInfoContainer>
            </CardContainer>
        );
    }
}

CardAnuncio.propTypes = {
    vitrine: PropTypes.string,
    vitrineNome: PropTypes.string,
    titulo: PropTypes.string,
    descricao: PropTypes.string,
    localizacao: PropTypes.string,
    valor: PropTypes.number,
    thumbnail: PropTypes.string,
    url: PropTypes.string,
    detalhes: PropTypes.object,
    isFavorite: PropTypes.bool
};

CardAnuncio.defaultProps = {
    prioridade: "BAIXA"
};

export default CardAnuncio;
