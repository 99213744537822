import {
    FETCH_ANUNCIOS_LIST,
    FETCH_ANUNCIO_BY,
    CLICK_UPDATE_ANUNCIO,
    FETCH_STATE_LIST,
    FETCH_CITY_LIST,
    FETCH_MARCAS_LIST,
    FETCH_MODELOS_LIST,
    FETCH_ESTILOS
} from '../actions/types';

const INITIAL_STATE = {
    searchFilterData: [],
    anuncioById: {},
    estilosList: [],
    marcasList: [],
    modeloList: [],
    stateList: [],
    cityList: [],
    bairroList: [],
    secoesList: [],
    filterStoraged: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ANUNCIOS_LIST:
            return { ...state, searchFilterData: action.payload }
        case FETCH_ANUNCIO_BY:
            return { ...state, anuncioById: action.payload }
        case CLICK_UPDATE_ANUNCIO:
            return { ...state, selectedCategoria: action.selectedCategoria };

        case FETCH_ESTILOS:
            return { ...state, estilosList: action.payload }
        case FETCH_MARCAS_LIST:
            return { ...state, marcasList: action.payload }
        case FETCH_MODELOS_LIST:
            return { ...state, modeloList: action.payload }

        case FETCH_STATE_LIST:
            return { ...state, stateList: action.payload }
        case FETCH_CITY_LIST:
            return { ...state, cityList: action.payload }

        default:
            return state;
    }
}