import React from "react";
import styled from "styled-components";

// TODO: Criar objeto com as cores

// Components
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { darken } from "@material-ui/core/styles/colorManipulator";

// Transition
import { Collapse } from "react-collapse";

// Icons
import MailOutline from "@material-ui/icons/MailOutline";
import Facebook from "./../../resources/svg/facebook-logo.svg";
import Twitter from "./../../resources/svg/twitter.svg";

// Components personalizados
export const Container = styled.div`
    width: ${props => (props.width ? props.width : "100%")};
    padding: ${props => (props.noPadding ? null : "30px 10px")};

    background-image: url(${props =>
        props.backgroundImg ? props.backgroundImg : null});
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : "unset"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;

    margin-top: ${props => (props.firstContainer ? "40px" : null)};
    padding-top: ${props => (props.firstContainer ? "80px" : null)};

    display: ${props => (props.onlyMobile ? "none" : "flex")};
    flex-wrap: wrap;

    flex-direction: ${props => (props.direction ? props.direction : "row")};
    align-items: ${props => (props.align ? props.align : "center")};
    justify-content: ${props => (props.justify ? props.justify : "center")};

    @media screen and (max-width: 768px) {
        overflow-x: hidden;
        margin-top: 0;
        padding-top: ${props =>
        props.paddingTopOnBreak
            ? props.paddingTopOnBreak
            : props => (props.firstContainer ? "80px" : null)};
        display: ${props => (props.noMobile ? "none" : "flex")};
    }
`;

export const ContainerTopo = styled.div`
    width: ${props => (props.width ? props.width : "100%")};
    padding: ${props => (props.noPadding ? null : "30px 10px")};

    background-image: url(${props =>
        props.backgroundImg ? props.backgroundImg : null});
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : "unset"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;

    margin-top: ${props => (props.firstContainer ? "70px" : null)};

    display: ${props => (props.onlyMobile ? "none" : "flex")};
    flex-wrap: wrap;

    flex-direction: ${props => (props.direction ? props.direction : "row")};
    align-items: ${props => (props.align ? props.align : "center")};
    justify-content: ${props => (props.justify ? props.justify : "center")};

    @media screen and (max-width: 768px) {
        margin-top: 0;
        padding-top: ${props =>
        props.paddingTopOnBreak
            ? props.paddingTopOnBreak
            : props => (props.firstContainer ? "52px" : null)};
        display: ${props => (props.noMobile ? "none" : "flex")};
    }
`;

export const ContainerTitulo = styled.div`
    width: 100%;
    max-width: ${props => (props.maxWidth ? props.maxWidth : "auto")};
    padding: ${props => (props.padding ? props.padding : "0")};
    display: flex;
    justify-content: ${props => (props.justify ? props.justify : "center")};
    align-content: center;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 0;
        margin-bottom: 10px;
        padding-top: ${props => (props.paddingTop ? props.paddingTop : "0")};
    }
`;

export const ContainerBtnMostrarMapaDesktop = styled.div`
    width: 238px;
    /* background-color: ${props =>
        props.mapaSelecionado ? "#ebebeb" : "#03bcd5"}; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: 0.2s;
    margin: 10px 0;
    height: 45px;
    label {
        display: flex;
        align-items: center;
    }

    span {
        margin-left: 10px;

    }
    /* color: ${props =>
        props.mapaSelecionado ? "#03bcd5 !important" : "white !important"}; */

    /* &:hover {
        cursor: pointer;
        background-color: ${props =>
        props.mapaSelecionado ? "#03bcd5" : "#ebebeb"};
        color: ${props =>
        props.mapaSelecionado ? "white !important" : "#03bcd5 !important"};
    } */

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const BtnMostrarMapa = styled.div`
    font-family: Helvetica;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const ContainerOpcaoVisualizacao = styled.div`
    position: fixed;
    z-index: 3;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 40px;
    font-weight: bold;
    font-size: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 204px;
    height: 42px;
    border-radius: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #04bcd5;
    margin: 20px auto 24px;
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const ContainerMapaMobile = styled.div`
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 2;
    top: 0;

    left: 0;
    right: 0;
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const ContainerBtnListaMapa = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const AlignCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const CardContainer = styled.div`
    width: calc(100% - 340px);
    max-width: 900px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

export const NoCardsFound = styled.div`
    display: flex;
    min-height: 50vh;
`;

export const Titulo = styled.p`
    font-size: 36px;
    color: #9b43c4;
    text-align: center;
    text-transform: uppercase;

    font-weight: bold;

    margin-top: 10px;
    margin-bottom: 20px;
`;

export const Subtitulo = styled.p`
    font-size: 21px;
    font-weight: 100;
    color: grey;
`;

export const CustomSubtitulo = styled.p`
    font-size: ${props => (props.fontSize ? props.fontSize : "18px")};
    color: ${props => (props.color ? props.color : "#626363")};

    margin-top: 0;
    margin-bottom: 0;

    display: flex;
    flex-wrap: wrap;

    flex-direction: ${props => (props.direction ? props.direction : "row")};
    align-items: ${props => (props.align ? props.align : "center")};
    justify-content: ${props => (props.justify ? props.justify : "center")};

    padding: ${props => (props.padding ? props.padding : "unset")};
    margin: ${props => (props.margin ? props.margin : "unset")};

    @media screen and (max-width: 768px) {
        display: ${props => (props.noMobile ? "none" : "flex")};
    }
`;

export const SimpleText = styled.p`
    font-size: 16px;
    font-weight: 100;
    color: grey;
    text-align: center;

    margin-top: 0;
    margin-bottom: 0;
`;

export const LogoContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const LogoVitrine = styled.img.attrs(props => ({
    width: '320px',
    height: '92px'
}))`
    width: 100%;
    max-width: 320px;
`;

export const LogoAnunciante = styled.img`
    width: 75%;
`;

export const VitrineTitulo = styled.p`
    font-size: 45px;
    font-weight: bold;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${props => (props.vitrinecolor ? props.vitrinecolor : "#000")};

    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;

    @media screen and (max-width: 1240px) {
        font-size: 31px;
    }

    @media screen and (max-width: 768px) {
        margin-right: 0;
    }
`;

export const TituloBusca = styled.h1`
    width: 500px;
    margin: 0 0 0.25em 0;
    margin-left: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1em;
    align-self: flex-end;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media screen and (max-width: 768px) {
        margin-top: 0.75em;
        margin-left: 0;
        justify-self: flex-start;
        width: 100%;
        text-align: center;
    }
`;

export const CallToActionBtn = styled(Button)`
    && {
        font-size: 16px;
        font-weight: bold;
        background: #9b43c4;
        color: #fff;
        text-align: center;
        line-height: 1.5;
        padding: 5px 30px;
        border-radius: 0;

        margin-left: 15px;
        margin-right: 15px;

        text-transform: initial;

        &:hover {
            background: ${darken("#9b43c4", 0.1)};
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
`;

// Components do Material
export const PurpleButton = styled(Button)`
    && {
        font-weight: bold;
        background: #9b43c4;
        color: white;

        padding: 10px 15px;

        &:hover {
            background: ${darken("#9b43c4", 0.1)};
        }

        @media screen and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
`;

export const TextButton = styled(Button)`
    && {
        font-size: 14px;
        color: ${props => (props.btncolor ? props.btncolor : "#9b43c4")};
        font-weight: 600;
        text-transform: uppercase;
        padding: 6px 8px;
        background-color: transparent;
        border: none;
        letter-spacing: unset;        
    }
`;

export const SocialButton = styled(Button)`
    && {
        width: 30px;
        height: 30px;
        margin-top: 10px;
        margin-left: 10px;
        min-width: initial;
        background-color: #fff;

        padding: 0;

        &:hover {
            background-color: ${darken("#FFFFFF", 0.1)};
        }

        span{
            width: 100%;
            height: 100%;
        }

        img{
            width: 70%;
            height: 70%;
        }

    }
`;

export const InputBusca = styled(props => (
    <TextField InputProps={{ classes: { root: "input-busca" } }} {...props} />
))`
    && {
        width: 100%;
        border-radius: 4px 0 0 4px;
        height: 60px;

        label,
        input {
            line-height: 21px;
            color: #9b43c4;
        }

        .input-busca {
            height: 60px;
            border-radius: 4px 0 0 4px;
            background-color: white;

            &:hover {
                border-color: ${darken("#9b43c4", 0.1)};
            }

            fieldset {
                border-color: #9b43c4;
                border-radius: 4px 0 0 4px;
            }
        }
    }
`;

export const SearchBtn = styled(PurpleButton)`
    && {
        height: 60px;
        border-radius: 0 4px 4px 0;
        top: 4px;
    }
`;

export const MoreButton = styled(Button)`
    && {
        width: 100%;
        max-width: 1024px;

        background-color: #fff;
        margin-bottom: 20px;

        min-height: 60px;
        font-size: 24px;
        color: #34146f;
        background-color: #fff;
        border-radius: 0;
        border: 2px solid #34146f;
        margin-bottom: 20px;

        &:hover {
            border-color: ${darken("#34146f", 0.1)};
        }
    }
`;

export const AnuncioContainer = styled.div`
    width: calc(100% - 350px);
    max-width: 924px;

    padding: 0 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: initial;
        margin-bottom: 10px;
        padding: 0;
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    position: relative;

    display: ${props => (props.showSeparator ? "block" : "none")};

    &::after {
        font-size: 24px;
        content: "";
        content: ${props => '"' + props.texto + '"'};
        padding: 0px 10px 5px 0px;
        border-bottom: ${props => "2px solid" + props.vitrinecolor};

        position: absolute;
        left: 0;
        bottom: 0;
    }

    @media screen and (max-width: 768px) {
        display: ${props => (props.showSeparator ? "flex" : "none")};
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        margin-top: 30px;

        &::after {
            position: relative;
        }
    }
`;

export const ContainerInfo = styled.div`
    display: ${props => (props.hideContainer ? "none" : "flex")};
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 72px;
    margin-left: ${props => (props.alignRight ? "auto" : null)};
    margin-right: ${props => (props.alignRight ? "60px" : null)};

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;

        height: auto;
        max-height: 72px;
        min-height: 2em;
    }
`;

export const CallToActionText = styled.p`
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 20px;

    @media screen and (max-width: 1240px) {
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

export const Text = styled.div`
    font-size: 18px;
    margin-bottom: 30px;
    padding-left: 20px;

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }
`;

export const ListUl = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 150px;
    @media screen and (max-width: 768px) {
        max-height: none;
    }
`;

export const TextLi = styled.li`
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 10px;
`;

export const IconsInfoContainer = styled.section`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

export const ContainerAnuncio = styled.div`
    height: ${props => (props.height ? props.height : "auto")};
    width: ${props => (props.width ? props.width : "100%")};

    display: ${props => (props.block ? "block" : "flex")};
    align-items: ${props => (props.alignItems ? props.alignItems : "center")};
    text-align: ${props => (props.textAlign ? props.textAlign : "center")};
    flex-direction: ${props =>
        props.flexDirection ? props.flexDirection : "row"};

    position: ${props => (props.absolute ? "absolute" : "relative")};
    top: ${props => (props.top ? props.top : "unset")};
    right: ${props => (props.right ? props.right : "unset")};
`;

export const IconInfo = styled.div`
    width: 20%;
    display: ${props => (props.hideInfo ? "none" : "flex")};
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding: 10px;

    @media screen and (max-width: 768px) {
        width: 33%;
    }
`;

export const Icon = styled(props => (
    <div {...props}>
        <img src={props.src} />
    </div>
))`
    width: 40px;
    height: 60px
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px){
        width: 33px;
        height: 33px;

        img {
            height: inherit;
            width: inherit;
        }
    }
`;

export const Info = styled.p`
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        text-align: center;
    }
`;

export const InfoSubtitulo = styled.h2`
    font-size: 17px;

    margin-top: 0;
`;

export const EmailIcon = styled(props => <MailOutline {...props} />)`
    && {
        font-size: 30px;
        color: #b00;
    }
`;

export const EmailShared = styled(props => (
    <a {...props}>
        <EmailIcon />
    </a>
))`
    && {
        width: 50px;
        height: 50px;
        border: 1px solid #b00;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }
`;

export const FacebookShared = styled(props => (
    <a {...props}>
        <img width="20" height="20" src={Facebook} />
    </a>
))`
    && {
        width: 50px;
        height: 50px;
        border: 1px solid #3d5a99;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 20px;
        }
    }
`;

export const TwitterShared = styled(props => (
    <a {...props}>
        <img width="20" height="20" src={Twitter} />
    </a>
))`
    && {
        width: 50px;
        height: 50px;
        border: 1px solid #419cd6;
        border-radius: 50%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 20px;
        }
    }
`;

export const AnuncianteTitle = styled.p`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
`;

export const AnuncianteText = styled.p`
    font-size: 16px;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 0;
`;

export const MapCollapse = styled(Collapse)`
    &&{
        .ReactCollapse--content{
            height: 100%;
        }
    }
`;

export const BannerContainer = styled.div`
    width: 970px;
    height: 175px;
    @media screen and (max-width: 768px) {
        width: 300px;
        height: 250px;            
    }
`;
