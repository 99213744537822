import React, { Component } from "react";
import { connect } from "react-redux";
import {
    fetchAnunciosList
} from "./../../actions";
import FilterSelect from "../filtro-select/FiltroSelect";
import SelectTag from "../select-tag/select-tag";
import { searchFilterList } from "./../../resources/mockups/filtro-mockup";
import { StringUtils } from "./../../resources/methods/StringUtils";

// Styles
import {
    FilterContainer,
    FilterInput,
    FilterLabel,
    Filter,
    FilterApplyBtn,
    SelectButton,
    SelectButtonContainer,
    FilterDualItemContainer,
    FilterAutocomplete
} from "./../commons/filter-styled";

// Colors
import { Theme } from "./../commons/vitrine-colors";

import { UrlsClassiUtils } from "./../../resources/methods/UrlsClassiUtils";
import Axios from "axios";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();
class FiltroBusca extends Component {
    constructor(props) {
        super(props);

        this.state = {
            aceitaFinanciamento: null,
            codigo: "",
            vitrine: "carros",
            inputValue: "",
            tipoDeBusca: {},
            categoryFilter: {},
            presetFiltro: {},
            anoFabricacaoMinimo: '',
            anoFabricacaoMaximo: '',
            cilindradaMinimo: '',
            cilindradaMaximo: '',
            quilometragemMinima: '',
            quilometragemMaxima: '',
            vlrMaximo: "",
            vlrMinimo: "",
            anuncianteList: [],
            anuncianteText: ""
        };

        this.cityFilterRef = React.createRef();
        this.marcaFilterRef = React.createRef();
        this.modeloFilterRef = React.createRef();
    }

    selectAnunciante = value => {
        this.props.handleFilter("anunciante", value);
    };

    handleInput = e => {
        let newState = { [e.target.name]: e.target.value };

        //Condição especial para o input do tipo palavra chave, garantido fim do preset de filtros no primeiro input manual.
        let presetFiltro = null;
        if (this.state.presetFiltro.codigo && e.target.name === "codigo") {
            presetFiltro = Object.assign(presetFiltro ? presetFiltro : {}, {
                codigo: ""
            });
        }

        if (presetFiltro) {
            newState["presetFiltro"] = Object.assign(
                this.state.presetFiltro,
                presetFiltro
            );
        }

        this.setState(newState);
    };

    handleSelect = (key, value) => {
        this.props.handleFilter(key, value.valor);
    };

    filterByBtn = name => {
        this.props.handleFilter(name, this.state[name]);
    };

    handleFinanciamento = (key, value) => {
        this.props.handleFilter(key, value);
        this.setState({ aceitaFinanciamento: value });
    };

    inputChange = () => {
        this.setState({
            inputValue: event.target.value
        });
    };

    componentDidUpdate(prevProps) {
        const { searchFilterData, presetFiltro } = this.props;

        if (searchFilterData && presetFiltro) {
            let estado = null;
            let cidade = null;
            let marca = null;
            let modelo = null;
            let estilo = null;
            let codigo = presetFiltro.codigo;
            let presetFiltroValues = {};

            if (searchFilterData.estados && presetFiltro.estado) {
                let indexEstado = searchFilterData.estados.findIndex(function (o) {
                    return o.descricao.toLowerCase() === presetFiltro.estado.toLowerCase();
                });
                estado = searchFilterData.estados[indexEstado];
            }
            if (searchFilterData.cidades && presetFiltro.cidade) {
                let indexCidade = searchFilterData.cidades.findIndex(function (o) {
                    return (
                        StringUtils.cleanString(o.descricao) ===
                        presetFiltro.cidade
                    );
                });
                cidade = searchFilterData.cidades[indexCidade];
            }
            if (searchFilterData.marcas && presetFiltro.marca) {
                let indexMarca = searchFilterData.marcas.findIndex(function (o) {
                    return (
                        o.valor === presetFiltro.marca
                    )
                })
                marca = searchFilterData.marcas[indexMarca]
            }
            if (searchFilterData.modelos && presetFiltro.modelo) {
                let indexModelo = searchFilterData.modelos.findIndex(function (o) {
                    return (
                        o.valor === presetFiltro.modelo
                    )
                })
                modelo = searchFilterData.modelos[indexModelo]
            }
            if (searchFilterData.estilosMoto && presetFiltro.estilo) {
                let indexEstilo = searchFilterData.estilosMoto.findIndex(function (o) {
                    return (
                        o.valor === presetFiltro.estilo.toUpperCase()
                    )
                })
                estilo = searchFilterData.estilosMoto[indexEstilo];
            }

            Object.assign(
                presetFiltroValues,
                codigo ? { codigo: codigo } : null
            );

            Object.assign(
                presetFiltroValues,
                estado ? { estado: estado } : null,
            );
            Object.assign(
                presetFiltroValues,
                cidade ? { cidade: cidade } : null
            );
            Object.assign(
                presetFiltroValues,
                marca ? { marca: marca } : null
            );
            Object.assign(
                presetFiltroValues,
                modelo ? { modelo: modelo } : null
            );
            Object.assign(
                presetFiltroValues,
                estilo ? { estilo: estilo } : null
            );

            this.setState(
                Object.assign(
                    { presetFiltro: presetFiltroValues }
                )
            );
        }
    }

    // TODO: melhorar busca de filtro
    handleFilterPath = (key, value) => {
        this.props.handleFilter(key, value);

        if (key === "estilo") {
            this.marcaFilterRef.current.resetFilter();
            this.modeloFilterRef.current.resetFilter();
        }

        if (key === "marca") {
            this.modeloFilterRef.current.resetFilter();
        }

        if (key == "estado") {
            this.cityFilterRef.current.resetFilter();
        }
    };

    filterArrayValor = () => {
        const { vlrMaximo, vlrMinimo } = this.state;

        if (
            vlrMaximo != "" &&
            vlrMinimo != "" &&
            parseFloat(vlrMaximo) < parseFloat(vlrMinimo)
        ) {
            this.setState({ vlrMinimo: vlrMaximo, vlrMaximo: vlrMinimo });
            this.props.handleFilter(
                ["vlrMinimo", "vlrMaximo"],
                [vlrMaximo, vlrMinimo]
            );
        } else {
            this.props.handleFilter(
                ["vlrMinimo", "vlrMaximo"],
                [this.state.vlrMinimo, this.state.vlrMaximo]
            );
        }
    };

    filterArrayCilindrada = () => {
        const { cilindradaMaximo, cilindradaMinimo } = this.state;

        if (
            cilindradaMaximo != "" &&
            cilindradaMinimo != "" &&
            parseFloat(cilindradaMaximo) < parseFloat(cilindradaMinimo)
        ) {
            this.setState({ cilindradaMinimo: cilindradaMaximo, cilindradaMaximo: cilindradaMinimo });
            this.props.handleFilter(
                ["cilindradaMinimo", "cilindradaMaximo"],
                [cilindradaMaximo, cilindradaMinimo]
            );
        } else {
            this.props.handleFilter(
                ["cilindradaMinimo", "cilindradaMaximo"],
                [this.state.cilindradaMinimo, this.state.cilindradaMaximo]
            );
        }
    };

    filterArrayQuilometragem = () => {
        const { quilometragemMinima, quilometragemMaxima } = this.state;

        if (quilometragemMaxima != "" && quilometragemMinima != "" && parseFloat(quilometragemMaxima) < parseFloat(quilometragemMinima)) {
            this.setState({ quilometragemMinima: quilometragemMaxima, quilometragemMaxima: quilometragemMinima });
            this.props.handleFilter(["quilometragemMinima", "quilometragemMaxima"], [quilometragemMaxima, quilometragemMinima]);
        } else {
            this.props.handleFilter(["quilometragemMinimo", "quilometragemMaxima"], [quilometragemMinima, quilometragemMaxima]);
        }
    };

    filterArrayAnoFabricacao = () => {
        const { anoFabricacaoMinimo, anoFabricacaoMaximo } = this.state;

        if (
            anoFabricacaoMaximo != "" &&
            anoFabricacaoMinimo != "" &&
            parseFloat(anoFabricacaoMaximo) < parseFloat(anoFabricacaoMinimo)
        ) {
            this.setState({ areaMinima: areaMaxima, areaMaxima: areaMinima });
            this.props.handleFilter(
                ["areaMinima", "areaMaxima"],
                [areaMaxima, areaMinima]
            );
        } else {
            this.props.handleFilter(
                ["anoFabricacaoMinimo", "anoFabricacaoMaximo"],
                [this.state.anoFabricacaoMinimo, this.state.anoFabricacaoMaximo]
            );
        }
    };

    getAnuncianteOptions = async inputValue => {
        if (!inputValue || !(typeof inputValue === "string")) {
            return [];
        }

        const response = await Axios.get(
            `${process.env.API_URL}/vitrine-motos/nome-fantasia/${inputValue}`
        ).then(response => {
            return response.data;
        });

        return response;
    };

    render() {
        const { searchFilterData } = this.props;
        let codigo = this.state.codigo || this.state.presetFiltro.codigo;

        if (!codigo) {
            codigo = "";
        }

        return (
            <FilterContainer direction="column" busca>
                {/* Tipo de veículo */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    {/* Estilo */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Estilo
                    </FilterLabel>

                    <FilterSelect
                        name={searchFilterList.estilo.name}
                        placeholder={searchFilterList.estilo.placeholder}
                        options={searchFilterData.estilosMoto ? searchFilterData.estilosMoto : undefined}
                        filterName={searchFilterList.estilo.filterName}
                        handleFilterPath={this.handleFilterPath}
                        defaultValue={this.state.presetFiltro.estilo}
                    />

                    {/* Marca */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Marca
                    </FilterLabel>

                    <FilterSelect
                        name={searchFilterList.marca.name}
                        placeholder={searchFilterList.marca.placeholder}
                        options={searchFilterData.marcas ? searchFilterData.marcas : undefined}
                        filterName={searchFilterList.marca.filterName}
                        handleFilterPath={this.handleFilterPath}
                        defaultValue={this.state.presetFiltro.marca}
                        ref={this.marcaFilterRef}
                    />

                    {/* Modelo */}
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Modelo
                    </FilterLabel>

                    <FilterSelect
                        name={searchFilterList.modelo.name}
                        placeholder={searchFilterList.modelo.placeholder}
                        options={searchFilterData.modelos ? searchFilterData.modelos : undefined}
                        filterName={searchFilterList.modelo.filterName}
                        handleFilterPath={this.handleFilterPath}
                        defaultValue={this.state.presetFiltro.modelo}
                        isDisabled={searchFilterData.modelos ? false : true}
                        ref={this.modeloFilterRef}
                    />
                </Filter>

                {/*  ESTADO/CIDADE */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    {/* ESTADO */}
                    <React.Fragment>
                        <FilterLabel
                            vitrinecolor={Theme[vitrineType].main}
                            busca
                        >
                            {searchFilterList.state.label}
                        </FilterLabel>
                        <FilterSelect
                            name={searchFilterList.state.name}
                            placeholder={searchFilterList.state.placeholder}
                            options={searchFilterData.estados ? searchFilterData.estados : undefined}
                            filterName={searchFilterList.state.filterName}
                            handleFilterPath={this.handleFilterPath}
                            defaultValue={this.state.presetFiltro.estado}
                        />
                    </React.Fragment>

                    {/* CIDADE */}
                    <React.Fragment>
                        <FilterLabel
                            vitrinecolor={Theme[vitrineType].main}
                            busca
                        >
                            {searchFilterList.city.label}
                        </FilterLabel>
                        <FilterSelect
                            name={searchFilterList.city.name}
                            placeholder={searchFilterList.city.placeholder}
                            options={
                                searchFilterData.cidades
                                    ? searchFilterData.cidades
                                    : undefined
                            }
                            filterName={searchFilterList.city.filterName}
                            handleFilterPath={this.handleFilterPath}
                            isDisabled={searchFilterData.cidades ? false : true}
                            defaultValue={this.state.presetFiltro.cidade}
                            ref={this.cityFilterRef}
                        />
                    </React.Fragment>
                </Filter>

                {/* Documentação */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Documentação
                    </FilterLabel>
                    <SelectTag
                        name={searchFilterList.documentacao.name}
                        placeholder={
                            searchFilterList.documentacao.placeholder
                        }
                        options={
                            searchFilterData.documentacoes
                                ? searchFilterData.documentacoes
                                : undefined
                        }
                        filterName={
                            searchFilterList.documentacao.filterName
                        }
                        handleFilterPath={this.handleFilterPath}
                    />
                </Filter>

                {/* Cilindrada */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Qual a cilindrada desejada?
                    </FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput
                            placeholder={searchFilterList.cilindradaMinimo.placeholder}
                            value={this.state.cilindradaMinimo}
                            name={searchFilterList.cilindradaMinimo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                        <span>a</span>
                        <FilterInput
                            placeholder={searchFilterList.cilindradaMaximo.placeholder}
                            value={this.state.cilindradaMaximo}
                            name={searchFilterList.cilindradaMaximo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={this.filterArrayCilindrada}
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>

                {/* Ano de fabricação */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Qual ano de fabricação?
                    </FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput
                            placeholder={searchFilterList.anoFabricacaoMinimo.placeholder}
                            value={this.state.anoFabricacaoMinimo}
                            name={searchFilterList.anoFabricacaoMinimo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                        <span>a</span>
                        <FilterInput
                            placeholder={searchFilterList.anoFabricacaoMaximo.placeholder}
                            value={this.state.anoFabricacaoMaximo}
                            name={searchFilterList.anoFabricacaoMaximo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={this.filterArrayAnoFabricacao}
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>

                {/* Quilometragem */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Qual a quilometragem desejada?
                    </FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput
                            placeholder={searchFilterList.quilometragemMinima.placeholder}
                            value={this.state.quilometragemMinima}
                            name={searchFilterList.quilometragemMinima.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                        <span>a</span>
                        <FilterInput
                            placeholder={searchFilterList.quilometragemMaxima.placeholder}
                            value={this.state.quilometragemMaxima}
                            name={searchFilterList.quilometragemMaxima.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={this.filterArrayQuilometragem}
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>

                {/* Preço minimo e maximo */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Qual a faixa de preço procura?
                    </FilterLabel>

                    <FilterDualItemContainer>
                        <FilterInput
                            placeholder={searchFilterList.vlrMinimo.placeholder}
                            value={this.state.vlrMinimo}
                            name={searchFilterList.vlrMinimo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                        <span>a</span>
                        <FilterInput
                            placeholder={searchFilterList.vlrMaximo.placeholder}
                            value={this.state.vlrMaximo}
                            name={searchFilterList.vlrMaximo.filterName}
                            vitrinecolor={Theme[vitrineType].main}
                            onChange={this.handleInput}
                            busca
                        />
                    </FilterDualItemContainer>

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={this.filterArrayValor}
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>

                {/* Caracteristicas do carro */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    {/* Cor */}
                    <FilterLabel
                        vitrinecolor={Theme[vitrineType].main}
                        busca
                    >
                        Qual a cor procurada?
                    </FilterLabel>

                    <SelectTag
                        name={searchFilterList.corVeiculo.name}
                        placeholder={
                            searchFilterList.corVeiculo.placeholder
                        }
                        options={
                            searchFilterData.cores
                                ? searchFilterData.cores
                                : undefined
                        }
                        filterName={
                            searchFilterList.corVeiculo.filterName
                        }
                        handleFilterPath={this.handleFilterPath}
                    />

                    {/* Acessórios */}
                    <FilterLabel
                        vitrinecolor={Theme[vitrineType].main}
                        busca
                    >
                        Quais acessórios desejados?
                    </FilterLabel>

                    <SelectTag
                        name={searchFilterList.acessorios.name}
                        placeholder={
                            searchFilterList.acessorios.placeholder
                        }
                        options={
                            searchFilterData.caracteristicas
                                ? searchFilterData.caracteristicas
                                : undefined
                        }
                        filterName={
                            searchFilterList.acessorios.filterName
                        }
                        handleFilterPath={this.handleFilterPath}
                    />

                    {/* Aceita financiamento */}
                    <FilterLabel
                        vitrinecolor={Theme[vitrineType].main}
                        busca
                    >
                        Aceita financiamento:
                    </FilterLabel>

                    <SelectButtonContainer busca>
                        <SelectButton
                            selected={this.state.aceitaFinanciamento}
                            vitrinecolor={Theme[vitrineType].main}
                            onClick={() => this.handleFinanciamento(searchFilterList.aceitaFinancimaneto.filterName, true)}
                            busca="true"
                            bordercolor={Theme[vitrineType].main}
                        >
                            Sim
                        </SelectButton>

                        <SelectButton
                            selected={!this.state.aceitaFinanciamento}
                            vitrinecolor={Theme[vitrineType].main}
                            onClick={() => this.handleFinanciamento(searchFilterList.aceitaFinancimaneto.filterName, false)}
                            busca="true"
                            bordercolor={Theme[vitrineType].main}
                        >
                            Não
                        </SelectButton>
                    </SelectButtonContainer>

                </Filter>

                {/* Pesquisa por anunciante / Aceita Financiamento */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        {searchFilterList.anunciante.label}
                    </FilterLabel>

                    <FilterAutocomplete
                        placeholder="Digite o nome do anunciante"
                        cacheOptions
                        loadOptions={this.getAnuncianteOptions}
                        isClearable
                        isSearchable
                        controlShouldRenderValue
                        onChange={this.selectAnunciante}
                        getOptionLabel={option => option.descricao}
                        vitrinecolor={Theme[vitrineType].main}
                    />
                </Filter>

                {/* Busca por código */}
                <Filter
                    direction="column"
                    vitrinecolor={Theme[vitrineType].main}
                    busca
                >
                    <FilterLabel vitrinecolor={Theme[vitrineType].main} busca>
                        Busca por código
                    </FilterLabel>
                    <FilterInput
                        name={searchFilterList.code.name}
                        placeholder={searchFilterList.code.placeholder}
                        value={codigo}
                        key={searchFilterList.code.filterName}
                        vitrinecolor={Theme[vitrineType].main}
                        onChange={this.handleInput}
                    />

                    <FilterApplyBtn
                        vitrinecolor={Theme[vitrineType].main}
                        onClick={() =>
                            this.filterByBtn(searchFilterList.code.name)
                        }
                    >
                        Aplicar
                    </FilterApplyBtn>
                </Filter>
            </FilterContainer>
        );
    }
}

const mapStateToProps = store => ({
});

export default connect(
    mapStateToProps,
    { fetchAnunciosList }
)(FiltroBusca);
