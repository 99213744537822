import { StringUtils } from "./StringUtils";
import { CategoriasMock } from "../mockups/home-mockup";

/**
 * Suporte as URL's e suas variações de acordo com as as regras do Classi
 */
export const UrlsClassiUtils = {
    URL_ESTADO_REGEX: /busca\/[\w+\+]+/, //Match para "(...)/\w\w-(...)" ou "/\w\w$" que é a condição de UF na URL do Classi.
    URL_CIDADE_REGEX: /\/\w{2}\-[\w+\+]+\/{0,1}/, //Match para "(...)/{{UF}}-\w+[/(...)|$]" que é a condição de Cidade na URL do Classi.
    URL_MARCA_REGEX: /busca\/([\w\+]+\-[\w\+]+|[\w\+]+)\/[\w\+]+\/[\w\+]+/,
    URL_MODELO_REGEX: /busca\/([\w\+]+\-[\w\+]+|[\w\+]+)\/[\w\+]+\/[\w\+]+\/[\w\+]+/,
    URL_BAIRRO_REGEX: /\/\w{2}\-[\w+\+]+\-[\w+\+]+\/{0,1}/,
    URL_NUMERO_QUARTOS_REGEX: /\d+\-quarto/,
    URL_TIPO_BUSCA_REGEX: /\/busca\/\w+\/|\/busca\/\w+$/,
    URL_CATEGORIA_REGEX: /busca\/[\w\+]+\/[\w\+\-]+\/[\w\+]+/, //Match para o primeiro grupo de string após /\w\w-\w+/[MATCH], que é a condição para Categoria na URL.
    SEARCH_QUERY_REGEX: /query=.*&|query=.*$/,
    SEARCH_CODIGO_REGEX: /codigo=.*&|codigo=.*$/,
    SEARCH_PAGINA_REGEX: /pagina=\d+/,

    TIPOS_DE_BUSCA: {
        IMOVEL_VENDA: "IMOVEL_VENDA",
        IMOVEL_ALUGUEL: "IMOVEL_ALUGUEL"
    },

    ALIAS_TIPOS_DE_BUSCA: {
        IMOVEL_VENDA: "comprar",
        IMOVEL_ALUGUEL: "alugar"
    },

    getEstado: function (url) {
        let estado = StringUtils.getPrimeiroMatch(url, this.URL_ESTADO_REGEX);

        return estado ? estado.replace(/busca\//g, "") : estado;
    },
    getCidade: function (url) {
        let cidade = StringUtils.getPrimeiroMatch(url, this.URL_CIDADE_REGEX);

        if (cidade) {
            cidade = cidade.replace(/^\/\w{2}-/, "");
            cidade = cidade.replace("/", "");
        }

        return cidade;
    },
    getMarca: function (url) {
        let marca = StringUtils.getPrimeiroMatch(url, this.URL_MARCA_REGEX);

        if (marca) {
            marca = marca.replace(/busca\/([\w\+]+\-[\w\+]+|[\w\+]+)\/[\w\+]+\//, "");
            marca = marca.replace("/", "");
        }

        return marca;
    },
    getModelo: function (url) {
        let modelo = StringUtils.getPrimeiroMatch(url, this.URL_MODELO_REGEX);

        if (modelo) {
            modelo = modelo.replace(/busca\/([\w\+]+\-[\w\+]+|[\w\+]+)\/[\w\+]+\/[\w\+]+\//, "");
            modelo = modelo.replace("/", "");
        }

        return modelo;
    },
    getTipoBusca: function (url) {
        let tipoBusca = StringUtils.getPrimeiroMatch(
            url,
            this.URL_TIPO_BUSCA_REGEX
        );

        if (tipoBusca) {
            tipoBusca = tipoBusca.replace("busca/", "");
            tipoBusca = tipoBusca.replace(/\//g, "");
        }

        return tipoBusca;
    },
    getCodigo: function (search) {
        let codigo = StringUtils.getPrimeiroMatch(
            search,
            this.SEARCH_CODIGO_REGEX
        );

        if (codigo) {
            codigo = codigo.replace("codigo=", "");
            codigo = codigo.replace("&", "");
        }

        return codigo;
    },
    getPagina: function (search) {
        let pagina = StringUtils.getPrimeiroMatch(
            search,
            this.SEARCH_PAGINA_REGEX
        );

        return pagina ? pagina.replace("pagina=", "") : pagina;
    },
    getArrayTipoBusca: function () {
        return Object.values(this.ALIAS_TIPOS_DE_BUSCA);
    },
    getAliasTipoDeBuscaByKey: function (key) {
        return this.ALIAS_TIPOS_DE_BUSCA[key];
    },
    getKeyTipoDeBuscaByAlias: function (alias) {
        return Object.keys(this.ALIAS_TIPOS_DE_BUSCA).find(
            key => this.ALIAS_TIPOS_DE_BUSCA[key] === alias
        );
    },
    /**
     * Recebe o pathname, mais a UF, Cidade e a Categoria, retornando o nome pathname com os dados.
     * Lembrando que categoria somente é inserida no pathname se existir uf e cidade, e cidade só
     * e inserida se existir uf.
     *
     * Exemplo:
     *  pathname: busca/vagas/uf-go/domestica
     *  uf: DF
     *  cidade: Brasília
     *  categoria: Doméstica
     *
     *  retorno: 'busca/vagas/df-brasilia/domestica'
     *
     * @param {String} pathname
     * @param {String} uf
     * @param {String} cidade
     * @param {String} categoria
     */
    makeNewPathname: function (
        pathname,
        ufEstado,
        cidade,
        anunciante,
        marca,
        modelo
    ) {
        let newPathname = "";

        if (pathname) {
            let pathnameBase = StringUtils.getPrimeiroMatch(pathname, /\w+\//);

            if (pathnameBase && pathnameBase.length) {
                pathnameBase = pathnameBase.replace("/", "");

                newPathname =
                    pathnameBase +
                    this.constructFiltrosPathname(
                        ufEstado,
                        cidade,
                        anunciante,
                        marca,
                        modelo
                    );
            }
        }

        return newPathname;
    },
    /**
     * Cria a string search, a partir dos parametros de query e pagina passado como valores.
     *
     * Exemplo:
     *  searchString: 'pagina=1&query=cola'
     *  query: 'Ferro de Passar'
     *  pagina: '2'
     *
     *  retorno 'pagina=2&query=Ferro de Passar'
     *
     * @param {String} searchString
     * @param {String} query
     * @param {String} pagina
     * @param {Boolean} stayInPage Caso verdadeiro utiliza a página (se existir) da searchString, caso contrário utiliza o valor de 'pagina' e em último caso '1'.
     */
    makeNewSearch: function (searchString, query, pagina, codigo, stayInPage, estilo) {
        let search = "";

        console.log(estilo);

        if (searchString) {
            let queryString = "";
            let codigoString = "";
            let estiloString = "";

            if (codigo) {
                codigoString = "codigo=" + codigo;
            }
            if (query) {
                queryString = "query=" + query;
            }

            if (estilo) {
                estiloString = "estilo=" + estilo;
            }

            let page = "pagina=" + (pagina || pagina === 0 ? pagina : "1");
            if (stayInPage) {
                let pagePos = this.getPagina(searchString);

                if (pagePos) {
                    page = "pagina=" + pagePos;
                }
            }

            search = page + (codigoString ? "&" + codigoString : "");
            search = search + (queryString ? "&" + queryString : "");
            search = search + (estiloString ? "&" + estiloString : "");
        }

        return search;
    },
    /**
     * Monta o pathname de busca de acordo com o tipo de busca informado.
     * Para conveniência o tipoDeBusca pode ser os valores setados no UrlsClassiUtils.TIPO_DE_BUSCA
     *
     * Exemplo:
     *  tipoDeBusca: 'vagas'
     *
     *  retorno 'busca/vagas/'
     *
     * @param {String} tipoDeBusca
     */
    makeBuscaPathnamePorTipoBusca: function (tipoDeBusca) {
        let newPathname = "";

        if (tipoDeBusca) {
            newPathname = "/busca/" + tipoDeBusca + "/";
        }

        return newPathname;
    },
    /**
     * Constrói os filtro para pathname com base nos parâmetros passados.
     *
     * Exemplo:
     *  uf: GO
     *  cidade: Goiânia
     *  bairro: Novo Mundo
     *  categoria: undefined
     *
     *  retorno 'df-brasilia/'
     *
     * @param {String} uf
     * @param {String} cidade
     * @param {String} bairro
     * @param {String} categoria
     * @param {String} numeroQuartos
     */
    constructFiltrosPathname: function (
        uf,
        cidade,
        garagem,
        marca,
        modelo,
        ano
    ) {
        let filtroPathname = "";

        if (uf && cidade) {
            filtroPathname += `/${StringUtils.cleanString(uf).toLowerCase()}-${StringUtils.cleanString(cidade).toLowerCase()}`;
        } else if (uf) {
            filtroPathname += `/${StringUtils.cleanString(uf).toLowerCase()}`;
        } else {
            filtroPathname += `/brasil`
        }

        if (garagem) {
            filtroPathname += `/${StringUtils.cleanString(garagem).toLowerCase()}`;
        } else {
            filtroPathname += `/estoque`
        }

        if (marca) {
            filtroPathname += `/${StringUtils.cleanString(marca).toLowerCase()}`
        }

        if (modelo) {
            filtroPathname += `/${StringUtils.cleanString(modelo).toLowerCase()}`
        }

        if (ano && modelo) {
            filtroPathname += `/${StringUtils.cleanString(ano).toLowerCase()}`
        }

        return filtroPathname;
    }
};
