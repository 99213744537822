import React from 'react';
import App from './App';
import HomePage from './pages/Home/HomePage';
import BuscaPage from './pages/Busca/BuscaPage';
import AnuncioPage from './pages/Anuncio/AnuncioPage';
import Error404 from './pages/Erros/Error404';
import Error50x from './pages/Erros/Error50x';

export default [
  {
    ...App,
    routes: [
      {
        ...HomePage,
        path: '/',
        exact: true
      },
      {
        ...BuscaPage,
        path: '/busca'
      },
      {
        ...AnuncioPage,
        path: '/anuncio/:id',
      },
      {
        ...Error50x,
        path: '/50x'
      },
      {
        ...Error404,
        path: '/404'
      },
      {
        ...Error404
      }
    ]
  }
];
