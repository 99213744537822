import React, { Component } from 'react';
import { Container } from '../select-anuncie/select-anuncie-styled';

//Style
import { Text, Image, ContainerNoResults } from './noResults-styled';

//Imgs
import Lupa from './../../resources/imgs/empty-states.png';

export default class NoResults extends Component {


    render() {

        return (
            <ContainerNoResults orientation="row">
                <Text>
                    Infelizmente não conseguimos localizar nenhum anúncio com a combinação de filtros escolhida.
                    <br />
                    Por favor altere os filtros e refaça a busca.
                </Text>
                <Image src={Lupa} />
            </ContainerNoResults>
        );

    }
} 