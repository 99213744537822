import styled from "styled-components";
import NativeSelect from "@material-ui/core/NativeSelect";

export const OrdenacaoContainer = styled.div`
    && {
        font-size: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        color: #940057;
        font-weight: bold;
        @media screen and (max-width: 768px) {
            justify-self: center;
            /* width: 100%; */
        }
    }
`;

export const OrdenacaoLabel = styled.p`
    && {
        margin-bottom: 0;
        margin-right: 10px;
    }
`;

export const OrdenacaoList = styled(NativeSelect).attrs({
    classnameprefix: "order"
})`
    && {
        font-size: 16px;
        margin-top: 1em;

        svg {
            color: ${props => props.vitrinecolor};
        }

        p {
            margin-top: 5px;
        }

        div {
            margin-bottom: 5px;
        }

        select {
            padding-bottom: 0;
        }

        &:before,
        &:after {
            border: none;
        }

        &:hover {
            &:before,
            &:after {
                display: none;
            }
        }
    }
`;
