// TODO: Colocar secao para pegar do .env

export const mockupFiltro = {
    aceitaFinanciamento: null,
    aceitaTroca: null,
    anoFabricacaoMaximo: null,
    anoFabricacaoMinimo: null,
    categoria: null,
    codigo: null,
    corVeiculo: null,
    documentacao: null,
    finalPlaca: null,
    marca: null,
    modelo: null,
    ordenacao: null,
    pagina: 0,
    paginado: true,
    qtdRegistros: 12,
    quilometragemMaxima: null,
    quilometragemMinima: null,
    tipoCambio: null,
    versao: null,
    vlrMaximo: null,
    vlrMinimo: null
};

export const searchFilterList = {
    aceitaFinancimaneto: {
        name: "financiamentoFilter",
        label: "Aceita financiamento:",
        filterName: "aceitaFinanciamento"
    },
    anoFabricacaoMaximo: {
        name: 'anoFabricacaoMaximo',
        filterName: 'anoFabricacaoMaximo',
        placeholder: 'Máximo'
    },
    anoFabricacaoMinimo: {
        name: 'anoFabricacaoMinimo',
        filterName: 'anoFabricacaoMinimo',
        placeholder: 'Mínimo'
    },
    anunciante: {
        name: "anuncianteFilter",
        label: "Pesquisa por anunciante",
        filterName: "anunciante",
        placeholder: "Nome do anunciante"
    },
    acessorios: {
        name: "acessoriosFilter",
        label: "Quais acessórios desejados?",
        filterName: "caracteristicas",
        placeholder: "Selecione"
    },
    category: {
        name: "categoryFilter",
        label: "Categoria:",
        filterName: "categoria",
        placeholder: "Selecione a categoria"
    },
    city: {
        name: "cityFilter",
        label: "Cidade:",
        placeholder: "Selecione a cidade",
        filterName: "cidade"
    },
    cilindradaMaximo: {
        name: "cilindradaMaximo",
        filterName: 'cilindradaMaximo',
        placeholder: 'Máximo'
    },
    cilindradaMinimo: {
        name: "cilindradaMinimo",
        filterName: 'cilindradaMinimo',
        placeholder: 'Mínimo'
    },
    code: {
        name: "codigo",
        label: "Pesquisar por código:",
        filterName: "codigo",
        placeholder: 'Código'
    },
    corVeiculo: {
        name: "corVeiculo",
        label: "Qual a cor procurada?",
        filterName: "corVeiculo",
        placeholder: "Selecione"
    },
    documentacao: {
        name: "documentacao",
        label: "Documentação",
        filterName: "documentacao",
        placeholder: "Selecione"
    },
    estilo: {
        name: 'estilo',
        Label: 'Estilo',
        filterName: 'estilo',
        placeholder: 'Selecione'
    },
    finalPlaca: {
        name: "finalPlaca",
        label: "Qual final da placa?",
        filterName: "finalPlaca",
        placeholder: "Selecione"
    },
    marca: {
        name: "marca",
        label: "Marca",
        filterName: "marca",
        placeholder: "Selecione"
    },
    modelo: {
        name: "modelo",
        label: "Modelo",
        filterName: "modelo",
        placeholder: "Selecione"
    },
    quilometragemMaxima: {
        name: "quilometragemMaximoo",
        filterName: "quilometragemMaxima",
        placeholder: "Máximo"
    },
    quilometragemMinima: {
        name: "quilometragemMinima",
        filterName: "quilometragemMinima",
        placeholder: "Mínimo"
    },
    state: {
        name: "stateFilter",
        label: "Estado:",
        placeholder: "Selecione o estado",
        filterName: "estado"
    },
    vlrMinimo: {
        name: "valor-minimo",
        placeholder: "Minimo",
        filterName: "vlrMinimo"
    },
    vlrMaximo: {
        name: "valor-maximo",
        placeholder: "Máximo",
        filterName: "vlrMaximo"
    }
};
