import React from 'react';
import { FilterSelect, FilterLabel } from './../commons/filter-styled';
import { Theme } from './../commons/vitrine-colors';

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();
export default class FiltroSelect extends React.Component {
  state = {
    filterValue: null,
    firstValueSelected: false
  }

  componentDidMount() {
    const { selectedValue } = this.props;

    if (selectedValue) {
      this.setState({ filterValue: selectedValue });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filterValue, firstValueSelected } = this.state;

    // TODO: Limpar defaultValue depois que carrega a primeira vez
    if (!filterValue && !firstValueSelected && this.props.defaultValue) {
      console.log(this.props.defaultValue)
      this.handleChange(this.props.defaultValue);
      this.setState({ firstValueSelected: true });
    }

    if (this.props.options && prevProps.options !== this.props.options && filterValue) {
      let indexValue = this.props.options.findIndex(function (o) {
        return o.descricao === filterValue[0].descricao;
      });

      if (indexValue === -1) {
        //TODO: Limpar filtro
      }
    }
  }

  cleanString = value => {
    //TODO: mudar esse trecho de código para utilizar StringUtils.js, mas testar antes de realizar troca
    if (value) {
      return value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '+').replace(/\//g, '+').replace(/-/g, '+').replace(/\./g, '');
    }
  };

  handleChange = filterData => {
    const { options, filterName, handleFilterPath } = this.props;
    const { filterValue } = this.state;
    let newFilterValue = {};

    if (options) {
      if (filterData) {
        newFilterValue = Object.keys(filterData).length > 0 ? filterData.descricao : filterValue.descricao
      } else {
        // TODO: caso tenha valor inicial no localStorage ou proveniente do banco
        newFilterValue = filterValue;
      }

      newFilterValue = options.filter(option =>
        this.cleanString(option.descricao) === this.cleanString(newFilterValue)
      );

      if (!newFilterValue.length) { newFilterValue = null; } //Caso especial para quando filtro retorna []
    } else {
      newFilterValue = null
    }

    this.setState({ filterValue: newFilterValue });

    if (handleFilterPath && filterName) {
      handleFilterPath(filterName, newFilterValue ? newFilterValue[0].valor : null);
    }
  }

  resetFilter = () => {
    this.setState({ filterValue: null })
  }

  render() {
    const {
      name,
      placeholder,
      options,
      isDisabled
    } = this.props;

    const { filterValue } = this.state;

    return (
      <FilterSelect
        busca
        name={name}
        placeholder={placeholder}
        value={filterValue}
        options={options}
        getOptionLabel={option => option.descricao}
        getOptionValue={option => option.descricao}
        vitrinecolor={Theme[vitrineType].main}
        isSearchable={false}
        onChange={this.handleChange}
        isDisabled={isDisabled}
      />
    )
  }
}